import { useEffect, useState } from "react";
import styled from "styled-components";
import classNames from "classnames/bind";

import { Color, rem, rgba } from "../../utils/style";
import { Icons } from "../../utils/react-svg";

const Container = styled.div`
  display: flex;
  align-items: center;

  label {
    margin: 0 0 0 16px;
    font-weight: 500;
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    cursor: pointer;
  }
`;

const CustomCheckbox = styled.span`
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  border-radius: 4px;
  border: 1px solid ${rgba(Color.ritualBlue, 0.24)};

  &.checked {
    background-color: ${Color.ritualBlue};
    border: 1px solid ${Color.ritualBlue};
  }

  display: flex;
  align-items: center;
  justify-content: center;

  pointer-events: none;

  svg {
    height: 8px;
    width: 12px;

    path {
      fill: ${Color.white};
    }
  }
`;

const BrowserCheckbox = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    background-color: ${Color.white};
    content: "";
  }
`;

const CheckboxContainer = styled.div`
  position: relative;
  height: 24px;
  width: 24px;
`;

const CheckboxInput = (props) => {
  const { id, name, label, value, className } = props;
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };

  // Update the state to reflect that checked value passed in from the parent,
  // when it changes.
  useEffect(() => {
    setChecked(!!props.checked);
  }, [props.checked]);

  return (
    <Container className={className}>
      <CheckboxContainer>
        <BrowserCheckbox
          type="checkbox"
          id={id}
          name={name}
          value={value}
          checked={checked}
          onChange={handleChange}
        />
        <CustomCheckbox
          className={classNames({ checked })}
          data-testid="custom-checkbox"
        >
          {checked && <Icons.CheckRounded />}
        </CustomCheckbox>
      </CheckboxContainer>
      <label htmlFor={id}>{label}</label>
    </Container>
  );
};

export default CheckboxInput;
